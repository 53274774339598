import "./App.css";
import QRCode from "qrcode.react";
// import bg from "./asset/img/background.png";
// import logo from "./asset/img/logo.png";
// import telephone from "./asset/img/telphone.png";
import title from "./asset/img/title.png";
import logo from "./asset/img/logov2.png";
import bg from "./asset/img/backgroundv2.jpg";
import telephone from "./asset/img/telphonev2.png";
import tip from "./asset/img/tip.png";
import appleIcon from "./asset/img/appleIcon.png";
import androidIcon from "./asset/img/androidIcon.png";
import ba_bg from "./asset/img/ba_bg.png";

// Qrcode
// import and_qrcode from "./asset/img/and_qrcodev2.png";
// import ios_qrcode from "./asset/img/ios_qrcodev2.png";

// mobile
import app_bg from "./asset/img/app_bgv2.png";
import app_tip from "./asset/img/app_tipv2.png";
import app_tel from "./asset/img/app_telv2.png";
import app_btn from "./asset/img/app_btnv2.png";

import React, { useEffect, useState } from "react";

function App() {
  const [qrcodeIdx, setQrcodeIdx] = useState(0);
  const [wobble, setWobble] = useState(false);
  const [bounce, setBounce] = useState(false);

  const [platform, setPlatform] = useState(3); // 1 android 2 IOS 3 h5

  // QR
  const [and_qrcode, setAndQrcode] = useState(
    "https://qiniu.weigzhudong.cn/xingya/xydj-v2.1.0.1-f2ee0e.apk"
  );
  const [ios_qrcode] = useState(
    "https://apps.apple.com/cn/app/%E6%98%9F%E8%8A%BD%E7%9F%AD%E5%89%A7/id6460586819"
  );

  useEffect(() => {
    fetch("https://app.whjzjx.cn/shareapk/download/apk?channel=official")
      .then((res) => {
        return res?.text();
      })
      .then((res) => {
        const resUrl = JSON.parse(res)?.data?.url;
        if (resUrl) {
          setAndQrcode(resUrl);
        }
      });
    const getPlatform = () => {
      const UA = window.navigator.userAgent;
      if (/iPhone|iPad|iPod|iOS|Android/i.test(UA)) {
        return "app";
      }
      return "h5";
    };
    const getTerminalType = () => {
      const env = getPlatform();
      let type;
      switch (env) {
        case "app":
          type = /Android/i.test(window.navigator.userAgent)
            ? "android"
            : "ios";
          break;
        default:
          type = "h5";
          break;
      }
      return type;
    };
    const terminalType = getTerminalType();
    if (terminalType === "android") {
      setPlatform(1);
    } else if (terminalType === "ios") {
      // document.getElementById("canvas").style.display = "none";
      setTimeout(() => {
        document.getElementById("canvas_sakura").style.display = "none";
      }, 10000);
      setPlatform(2);
    } else {
      setPlatform(3);
    }
  }, []);
  const toDownUrl = () => {
    if (platform === 1) {
      window.location.href =
        and_qrcode ||
        "https://qiniu.weigzhudong.cn/xingya/xydj-v2.1.0.1-f2ee0e.apk";
    } else if (platform === 2) {
      window.location.href =
        "https://apps.apple.com/cn/app/%E6%98%9F%E8%8A%BD%E7%9F%AD%E5%89%A7/id6460586819";
    }
  };

  // records
  const [records, setRecords] = useState("");
  useEffect(() => {
    if (window.location.hostname.indexOf("duanju.com") > -1) {
      setRecords("浙ICP备2021037400号-2");
    }
    if (window.location.hostname.indexOf("xingya.com.cn") > -1) {
      setRecords("浙ICP备2021037400号-16");
    }
  }, [records]);
  return platform === 3 ? (
    <div className="App">
      <div className="App-container">
        <img src={bg} className="App-bg" alt="xybg" />
        <div className="App-title animate__animated animate__bounceInDown animate__delay-05s">
          <img
            src={title}
            alt="xytitle"
            onDoubleClick={() => alert(and_qrcode)}
          />
        </div>
        <div
          className="App-telephone animate__animated animate__backInLeft"
          onClick={() => {
            setWobble(true);
            setTimeout(() => {
              setWobble(false);
            }, 1000);
          }}
        >
          <img
            src={telephone}
            className={`animate__animated ${
              wobble === true ? "animate__wobble" : ""
            }`}
            alt="xytel"
          />
        </div>
        <div
          className="App-logo animate__animated animate__bounceInRight"
          onMouseEnter={() => {
            setBounce(true);
          }}
          onMouseLeave={() => {
            setBounce(false);
          }}
        >
          <img
            src={logo}
            className={`animate__animated ${
              bounce === true ? "animate__bounce animate__infinite" : ""
            }`}
            alt="xylogo"
          />
        </div>
        <div className="App-tip animate__animated animate__bounceInDown">
          <img src={tip} alt="xytip" />
        </div>
        <div className="App-qrcode animate__animated animate__fadeIn animate__delay-05s animate__slow">
          <QRCode
            value={qrcodeIdx ? and_qrcode : ios_qrcode}
            style={{ height: "auto", width: "100%" }}
            fgColor="#000000"
          />
        </div>
        <div className="App-ios" onClick={() => setQrcodeIdx(0)}>
          <img src={appleIcon} alt="xyios" />
        </div>
        <div className="App-android" onClick={() => setQrcodeIdx(1)}>
          <img src={androidIcon} alt="xyandroid" />
        </div>
      </div>
      <footer className="App-footer">
        ©2023 嘉兴九州文化传媒有限公司版权所有
        <a
          href="http://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {records}
        </a>
        <img src={ba_bg} alt="ba" className="ba" />
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=33049802000364"
          target="_blank"
          rel="noopener noreferrer"
        >
          浙公网安备33049802000364号
        </a>
      </footer>
    </div>
  ) : (
    <div className="app">
      <div className="app-bg">
        <img src={app_bg} alt="xyapp" />
      </div>
      <div className="app-tip animate__animated animate__bounceInDown">
        <img src={app_tip} alt="xyapp" />
      </div>
      <div className="app-tel animate__animated animate__backInDown animate__delay-05s">
        <img src={app_tel} alt="xyapp" />
      </div>
      <div
        className="app-btn animate__animated animate__jello animate__delay-1s"
        onClick={() => toDownUrl()}
      >
        <img src={app_btn} alt="xyapp" />
      </div>
      <div className="app-copy">
        <span>©2023 嘉兴九州文化传媒有限公司版权所有</span>
        <br />
        <span className="icp">
          <span>{records}</span>
          <img src={ba_bg} alt="ba" className="ba" />
          <span>浙公网安备33049802000364号</span>
        </span>
      </div>
    </div>
  );
}

export default App;
